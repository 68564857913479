import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image";
import styles from "./hero-subpage.module.scss";

const HeroBanner = ({ title, subtitle, image }) => (
  <StaticQuery
    query={graphql`
      query {
        homeHeroBanner: file(relativePath: { eq: "home-hero-banner.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2400, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <div className={styles.container}>
        <Img alt={`${title} banner`} className={styles.heroBanner} fluid={data.homeHeroBanner.childImageSharp.fluid} />
        <section className={styles.innerContainer}>
          <div className={styles.row}>
            <div className={styles.col1}>
              <h1 className={styles.heading}>{title}</h1>
              <p className={styles.paragraph}>{subtitle}</p>
            </div>
            <div className={styles.col2}>
              <img className={styles.heroImg} src={image} alt={`${title} icon`} />
            </div>
          </div>
        </section>
      </div>
    )}
  />
)

export default HeroBanner
