export default [
  {
    type: 'free',
    bgColor: '#f1fafa',
    titleColor: '#5063ef',
    title: 'Free',
    subtitle: '3 users',
    maxUsers: 3,
    price: 'Free',
    description: 'For small team to use for unlimited time.',
    features: [
      'Up to 3 active projects', '200MB file space',
      'Up to 3 users', 'Unlimited time',
      'Timetracking', 'Project management',
      'Design files storage & version management',
    ],
  },
  {
    type: 'pro',
    bgColor: '#f2f2fa',
    titleColor: '#5f95d3',
    title: 'Pro',
    subtitle: '5 - 30 users',
    maxUsers: 30,
    hasPrice: true,
    price: 11,
    yearPrice: 9,
    description: 'For medium team with extra disk space and users.',
    features: [
      'Up to 30 active projects', '10GB file space',
      '5 to 30 users', 'Unlimited time',
      'Timetracking', 'Project management',
      'Design files storage & version management',
    ],
  },
  {
    type: 'premium',
    bgColor: '#f6fbfc',
    titleColor: '#46b4ff',
    title: 'Premium',
    subtitle: '5 - 300 users',
    hasPrice: true,
    price: 17,
    yearPrice: 14,
    description: 'For large teams with extra disk space and users',
    features: [
      'Unlimited active projects', '100GB file space',
      '5 to 300 users', 'Unlimited time',
      'Timetracking', 'Project management',
      'Design files storage & version management',
    ],
  },
  {
    type: 'enterprise',
    bgColor: '#f2f2fa',
    titleColor: '#b593df',
    title: 'Enterprise',
    subtitle: 'unlimited users',
    price: 'Contact us',
    description: 'Suited to your needs',
    features: [
      'Unlimited active projects', 'Dedicated hosting',
      'Unlimited users', 'Unlimited time',
      'Timetracking', 'Project management',
      'Design files storage & version management',
    ],
  },
];
