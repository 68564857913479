import React from 'react';
import styles from "./switch.module.scss";

const Switch = ({ options, handleClick, value }) => (
  <div className={styles.switchOuter}>
    <div className={styles.switchBg} style={{ left: value.id !== options[1].id ? 0 : '50%' }} />
    {options[0] && options[0].name &&
    <button
      type="button"
      className={styles.switchBtn}
      onClick={() => handleClick(options[0])}
    >
      {options[0].name}
    </button>}
    {options[1] && options[1].name &&
    <button
      type="button" className={styles.switchBtn}
      onClick={() => handleClick(options[1])}
    >
      {options[1].name}
      <span className={styles.switchDescription}>{options[1].description}</span>
    </button>}
  </div>
)

export default Switch;
