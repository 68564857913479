import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import pricingMainIcon from '../images/pricing-main-icon.svg';
import PricingContent from '../components/PricingContent';
import HeroBannerSubpage from '../components/HeroBannerSubpage';

const Pricing = () => (
  <Layout page="pricing">
    <HeroBannerSubpage
      title="Select the right plan for your needs"
      subtitle="Choose the plan that suits your detailing team. You won't need to provide credit card to start using our 14 days trial plans."
      keywords={['pricing', 'plans', 'detailers']}
      image={pricingMainIcon}
    />
    <SEO title="Pricing" />
    <PricingContent />
  </Layout>
)

export default Pricing;
