import React, { Component } from 'react';
import Select from 'react-select';
import Switch from './Switch';
import PricingItem from './PricingItem';
import ConfirmModal from '../ConfirmModal';
import options from './options';
import styles from "./pricing-content.module.scss";

class PricingContent extends Component {
  constructor() {
    super();
    this.state = {
      interval: { id: 'year', name: 'Annually billed' },
      quantity: { value: 20, label: '20 employees' },
      plan: 'pro',
      isOpen: false,
      isSignupForm: true,
    };
    this.availableOptions = [
      { value: 3, label: '3 employees' },
      { value: 5, label: '5 employees' },
      { value: 10, label: '10 employees' },
      { value: 15, label: '15 employees' },
      { value: 20, label: '20 employees' },
      { value: 25, label: '25 employees' },
      { value: 30, label: '30 employees' },
      { value: 35, label: '35 employees' },
      { value: 40, label: '40 employees' },
      { value: 45, label: '45 employees' },
      { value: 50, label: '50 employees' },
      { value: 60, label: '60 employees' },
      { value: 70, label: '70 employees' },
      { value: 80, label: '80 employees' },
      { value: 90, label: '90 employees' },
      { value: 100, label: '100 employees' },
      { value: 125, label: '125 employees' },
      { value: 150, label: '150 employees' },
      { value: 175, label: '175 employees' },
      { value: 200, label: '200 employees' },
      { value: 250, label: '250 employees' },
      { value: 300, label: '300 employees' },
    ];
    this.paymentPeriod = [
      { id: 'month', name: 'Monthly billed' },
      { id: 'year', name: 'Annually billed', description: 'save up to 15%' },
    ]
    this.customStyles = {
      input: (provided, state) => ({
        borderRadius: '10px',
      }),
      container: (provided, state) => ({
        ...provided,
      }),
      option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #eee',
        backgroundColor: state.isSelected ? '#ddd' : 'white',
        color: state.isSelected ? '#333' : '#888',
        padding: 10,
        fontSize: 14,
        transition: '.3s all',
        '&:hover': {
          backgroundColor: '#eee',
        }
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
        const fontSize = 14;
        return { ...provided, opacity, transition, fontSize };
      }
    }
  }

  handleSwitch = val => {
    this.setState({ interval: val });
  }

  handleChangeTeam = val => {
    this.setState({ quantity: val });
  }

  handleSelectOption = val => {
    if (val.type === 'free') {
      this.setState({ quantity: { value: 3, label: '3 employees' } });
    } else if (this.state.quantity.value === 3) {
      this.setState({ quantity: { value: 5, label: '5 employees' } });
    } else if (val.type === 'pro' && this.state.quantity.value > 30) {
      this.setState({ quantity: { value: 30, label: '30 employees' } });
    }
    if (val.type !== 'enterprise') {
      this.setState({ plan: val.type });
      this.toggleOpen();
    }
  }

  getDetails = () => {
    const { interval, quantity, plan } = this.state;

    return ({
      interval: interval.id,
      intervalName: interval.name,
      quantity: quantity.value,
      quantityLabel: quantity.label,
      plan
    });
  }

  toggleOpen = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen, isSignupForm: true }));
  }

  toggleForm = () => {
    this.setState(prevState => ({ isSignupForm: false }));
  }

  getItemProps = a => (a.type === 'enterprise' ? {
    href: 'mailto:support@entracker.com?subject=Custom plan request'
  } : null)

  render() {
    const { isOpen, isSignupForm } = this.state;
    return (
      <div className={styles.switchWrap}>
        <div>
          <Switch
            value={this.state.interval}
            handleClick={this.handleSwitch}
            options={this.paymentPeriod}
          />
        </div>
        <div className={styles.selectWrap}>
          <p className={styles.teamSizeLabel}>Team size</p>
          <Select
            options={this.availableOptions}
            styles={this.customStyles}
            placeholder="Select..."
            value={this.state.quantity}
            onChange={this.handleChangeTeam}
            defaultValue={{ value: 20, label: '20 employees' }}
          />
        </div>
        <div className={styles.optionsWrap}>
        {options.map((a, i) =>
          // eslint-disable-next-line
          <a
            key={`plan-offer-item-${i}`}
            className={styles.cardOuterWrap}
            onClick={() => this.handleSelectOption(a)}
            {...this.getItemProps(a)}
          >
            <PricingItem
              plan={a}
              index={i}
              interval={this.state.interval}
            />
          </a>)}
        </div>
        <ConfirmModal
          isSignupForm={isSignupForm}
          isOpen={isOpen}
          toggleOpen={this.toggleOpen}
          customStyles={styles.emailModal}
          planDetails={this.getDetails()}
          toggleForm={this.toggleForm}
        />
      </div>
    );
  }
}

export default PricingContent;
