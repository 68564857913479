import React from 'react';
import cx from 'classnames';
import styles from './pricing-item.module.scss';

const PricingItem = ({ plan, interval, index, isSelected = false }) => {
    const pricePerUser = interval.id === 'year' ? plan.yearPrice : plan.price

    return (
        <div
          className={cx(
            styles.cardWrap,
            { [styles.isSelected]: isSelected },
          )}
        >
          <div
            className={styles.cardTop}
            style={{ backgroundColor: plan.bgColor }}
          >
            <h2 className={styles.title} style={{ color: plan.titleColor }}>{plan.title}</h2>
            <p className={styles.subtitle} style={{ color: plan.titleColor }}>{plan.subtitle}</p>
            <div className={styles.priceWrap}>
              {plan.hasPrice ?
                <p className={styles.price}>${pricePerUser}
                  <span className={styles.afterPrice}> / mo + tax</span>
                </p> :
                <p className={styles.price}>{plan.price}</p>}
              {plan.hasPrice &&
                <span className={styles.priceSub}>per user</span>}
            </div>
            <p className={styles.description}>{plan.description}</p>
          </div>
          <div className={styles.cardBot}>
            <div className={styles.featuresWrap}>
              {plan.features.map((b, c) => <p key={`plan-offer-${index}-feature-${c}`} className={styles.descRow}>{b}</p>)}
            </div>
            <div className={styles.selectBtnWrap}>
              {!isSelected ?
                <div
                  className={styles.selectBtn}
                  style={{ backgroundColor: plan.titleColor }}
                >START FOR FREE
                </div> :
                <div
                  className={styles.selectBtn}
                  style={{ backgroundColor: plan.titleColor }}
                >{isSelected ? 'SELECTED' : 'GET STARTED'}
                </div>}
            </div>
          </div>
        </div>
    );
  }

  export default PricingItem;
